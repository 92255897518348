<nb-card>
                <nb-card-header>
                        <h3 class="text-primary px-lg-3">Terms and Conditions </h3>
                </nb-card-header>
                <nb-card-body class="px-md-3 px-lg-5">
                                <p class="c5"><span class="c2">Please read the Terms and Conditions carefully before you start to use the Site.
                                        </span><span class="c6">The Terms and Conditions govern your access to and use of the Site and the services
                                            provided by Artistic Visions LLC</span><span class="c2">&nbsp;(&quot;</span><span
                                            class="c6">Company</span><span class="c2">,&quot; &quot;</span><span class="c6">we</span><span
                                            class="c2">,&quot; or &quot;</span><span class="c6">us</span><span class="c2">&quot;)</span><span
                                            class="c6">, whether you are a client of the Company&rsquo;s through a license agreement with the Company (a
                                            &ldquo;Licensee&rdquo;) or a client, or potential client or site visitor, of a Licensee who gains access to
                                            the benefits of the Site by contracting with a Licensee. &nbsp;</span></p>
                                    <p class="c5"><span class="c10">Acceptance of the Terms and Conditions</span><a id="id.30j0zll"></a></p>
                                    <p class="c5"><span class="c2">These Terms and Conditions are entered into by and between You and the Company. The
                                            following terms and conditions, together with the service contract, estimate, or order agreed to by you and
                                            the Company and any documents they expressly incorporate by reference (collectively, &quot;</span><span
                                            class="c6">Terms and Conditions</span><span class="c2">&quot;), govern your access to and use of the
                                            Company&rsquo;s website at </span><span class="c2"><a class="c34"
                                                href="https://www.google.com/url?q=http://www.artisticvisions.com&amp;sa=D&amp;ust=1567018000658000">www.artisticvisions.com</a></span><span
                                            class="c2">&nbsp;and www.avwaveinteractive.com (collectively, the &ldquo;</span><span
                                            class="c6">Website</span><span class="c2">&rdquo;) or the web application at www.avwaveinteractive.com (the
                                            &ldquo;</span><span class="c6">App</span><span class="c2">&rdquo;), including any content, functionality,
                                            and services offered on or through the Website or App (collectively, the &ldquo;</span><span
                                            class="c6">Site</span><a id="id.1fob9te"></a><span class="c2">&rdquo;), whether as a guest or a registered
                                            user. </span></p>
                                    <p class="c5">
                                            <span class="c6">By using the Site or by clicking to accept or agree to the Terms and Conditions when
                                            this option is made available to you, you accept and agree to be bound and abide by these Terms and
                                            Conditions and our Privacy Policy, found at 
                                                <a style="font-weight: 600;" routerLink="/auth/policy">Privacy Policy</a>
                                            
                                            , incorporated herein by reference (the &ldquo;Privacy Policy&rdquo;).</span>
                                            <a
                                            id="id.3znysh7"></a><span class="c2">&nbsp;If you do not want to agree to these Terms and Conditions or the
                                            Privacy Policy, you must not access or use the Site. </span></p>
                                    <p class="c5"><span class="c2">This Site is offered and available to users who are 16 years of age or older and
                                            reside in the United States or any of its territories or possessions. By using this Site, you represent and
                                            warrant that you are of legal age to form a binding contract with the Company by agreeing to these Terms and
                                            Conditions and meet all of the foregoing elig</span><a id="id.2et92p0"></a><span class="c2">ibility
                                            requirements. If you do not meet all of these requirements, you must not access or use the Site.</span></p>
                                    <p class="c5"><span class="c10">Changes to the Terms and Conditions</span><a id="id.tyjcwt"></a></p>
                                    <p class="c5"><span class="c2">We may revise and update these Terms and Conditions from time to time in our sole
                                            discretion. All changes are effective immediately when we post them, and apply to all access to and use of
                                            the Site thereafter. However, any changes to the dispute resolution provisions set out in </span><span
                                            class="c27">Governing Law and Jurisdiction</span><span class="c2">&nbsp;will not apply to any disp</span><a
                                            id="id.3dy6vkm"></a><span class="c2">utes for which the parties have actual notice on or before the date the
                                            change is posted on the Site.</span></p>
                                    <p class="c5"><span class="c2">Your continued use of the Site following the posting of revised Terms and Conditions
                                            means that you accept and agree to the changes. You are expected to c</span><a id="id.1t3h5sf"></a><span
                                            class="c2">heck this page frequently so you are aware of any changes, as they are binding on you. </span>
                                    </p>
                                    <p class="c5"><span class="c10">Use of the Site; Accessing the Site; and Account Security</span><a
                                            id="id.4d34og8"></a></p>
                                    <p class="c5"><span class="c2">Your access to the Site and the Company&rsquo;s services is dependent upon your
                                            reasonable use of the Site and services in compliance with these Terms and Conditions. &nbsp;We reserve the
                                            right to withdraw or amend this Site, and any service or material we provide on the Site, in our sole
                                            discretion without notice. We will not be liable if for any reason all or any part of t</span><a
                                            id="id.2s8eyo1"></a><span class="c2">he Site is unavailable at any time or for any period. From time to
                                            time, we may restrict access to some parts of the Site, or the entire Site, to users, including registered
                                            users.</span></p>
                                    <p class="c5"><span class="c2">You are responsible for both:</span></p>
                                    <ul class="c11 lst-kix_list_31-0 start">
                                        <li class="c0"><span class="c2">Making all arrangements necessary for you to have access to the Site.</span>
                                        </li>
                                    </ul><a id="id.17dp8vu"></a>
                                    <ul class="c11 lst-kix_list_31-0">
                                        <li class="c0"><span class="c2">Ensuring that all persons who access the Site through your internet connection
                                                are aware of these Terms and Conditions and comply with them.</span></li>
                                    </ul>
                                    <p class="c5"><span class="c2">To access the Site or some of the resources it offers, you may be asked to provide
                                            certain registration details or other information. It is a condition of your use of the Site that all the
                                            information you provide on the Site is correct, current, and complete. You agree that all information you
                                            provide to register with this Site or otherwise, including, but not limited to, through the use of any
                                            interactive features on the Site, is governed by our </span><span class="c25">Privacy Policy </span><a
                                            id="id.3rdcrjn"></a><span class="c2">(linked above), and you consent to all actions we take with respect to
                                            your information consistent with our Privacy Policy.</span></p>
                                    <p class="c5"><span class="c2">If you choose, or are provided with, a user name, password, or any other piece of
                                            information as part of our security procedures, you must treat such information as confidential, and you
                                            must not disclose it to any other person or entity. You also acknowledge that your account is personal to
                                            you and agree not to provide any other person with access to this Site or portions of it using your user
                                            name, password, or other security information. You agree to notify us immediately of any unauthorized access
                                            to or use of your user name or password or any other breach of security. You also agree to ensure that you
                                            exit from your account at the end of each session. You should use particular caution when accessing your
                                            account from a public or shared computer so that others are n</span><a id="id.26in1rg"></a><span
                                            class="c2">ot able to view or record your password or other personal information.</span></p>
                                    <p class="c5"><span class="c2">We have the right to disable any user name, password, or other identifier, whether
                                            chosen by you or provided by us, at any time in our sole discretion for any or no reason, including
                                            if</span><a id="id.lnxbz9"></a><span class="c2">, in our opinion, you have violated any provision of these
                                            Terms and Conditions.</span></p>
                                    <p class="c5"><span class="c10">Intellectual Property Rights</span><a id="id.35nkun2"></a></p>
                                    <p class="c5"><span class="c2">The Site and its entire contents, features, and functionality (including but not
                                            limited to all information, software, text, displays, images, video, and audio, and the design, selection,
                                            and arrangement thereof) are owned by the Company, its licensors, or other providers of such material and
                                            are protected by United States and international copyright, trademark, patent, trade secret, and other
                                            intel</span><a id="id.1ksv4uv"></a><span class="c2">lectual property or proprietary rights laws.</span></p>
                                    <p class="c5"><span class="c2">These Terms and Conditions permit you to use the Site for your personal,
                                            non-commercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly
                                            display, publicly perform, republish, download, store, or transmit any of the material on our Site, except
                                            as follows:</span></p>
                                    <ul class="c11 lst-kix_list_31-0">
                                        <li class="c0"><span class="c2">Your computer may temporarily store copies of such materials in RAM incidental
                                                to your accessing and viewing those materials.</span></li>
                                        <li class="c0"><span class="c2">You may store files that are automatically cached by your Web browser for
                                                display enhancement purposes.</span></li>
                                        <li class="c0"><span class="c2">You may print or download one copy of a reasonable number of pages of the Site
                                                for your own personal, non-commercial use and not for further reproduction, publication, or
                                                distribution.</span></li>
                                        <li class="c0"><span class="c2">If we provide desktop, mobile, or other applications for download, you may
                                                download a single copy to your computer or mobile device solely for your own personal, non-commercial
                                                use, provided you agree to be bound by our end user license agreement for such applications.</span></li>
                                        <li class="c0"><span class="c2">If we provide </span><span class="c25">social media features</span><a
                                                id="id.44sinio"></a><span class="c2">&nbsp;with certain content, you may take such actions as are
                                                enabled by such features.</span></li>
                                    </ul>
                                    <p class="c5"><span class="c2">You must not:</span></p>
                                    <ul class="c11 lst-kix_list_31-0">
                                        <li class="c0"><span class="c2">Modify copies of any materials from this site.</span></li>
                                        <li class="c0"><span class="c2">Use any illustrations, photographs, video or audio sequences, or any graphics
                                                separately from the accompanying text.</span></li>
                                    </ul><a id="id.2jxsxqh"></a>
                                    <ul class="c11 lst-kix_list_31-0">
                                        <li class="c0"><span class="c2">Delete or alter any copyright, trademark, or other proprietary rights notices
                                                from copies of materials from this site.</span></li>
                                    </ul>
                                    <p class="c5"><span class="c2">You must not access or use for any commercial purposes any part of the Site or any
                                            services or</span><a id="id.z337ya"></a><span class="c2">&nbsp;materials available through the Site. </span>
                                    </p>
                                    <p class="c5"><span class="c2">If you wish to make any use of material on the Site other than that set out in this
                                            section, please address your request to: <a href="mailto:support@avwaveinteractive.com">support@avwaveinteractive.com</a>.</span><a id="id.3j2qqm3"></a></p>
                                    <p class="c5"><span class="c2">If you print, copy, modify, download, or otherwise use or provide any other person
                                            with access to any part of the Site in breach of the Terms and Conditions, your right to use the Site will
                                            stop immediately and you must, at our option, return or destroy any copies of the materials you have made.
                                            No right, title, or interest in or to the Site or any content on the Site is transferred to you, and all
                                            rights not expressly granted are reserved by the Company. Any use of the Site not expressly permitted by
                                            these Terms and Conditions is a breach of these Terms and Conditi</span><a id="id.1y810tw"></a><span
                                            class="c2">ons and may violate copyright, trademark, and other laws.</span></p>
                                    <p class="c5"><span class="c10">Trademarks</span><a id="id.4i7ojhp"></a></p>
                                    <p class="c5"><span class="c2">The Company name, the terms Artistic Visions, AV Wave Interactive Solutions, the
                                            Company logos, and all related names, logos, product and service names, designs, and slogans are trademarks
                                            of the Company or its affiliates or licensors. You must not use such marks without the prior written
                                            permission of the Company. All other names, logos, product and service names, designs, and slogans on this
                                            Site are the trademarks of their respective ow</span><a id="id.2xcytpi"></a><span class="c2">ners.</span>
                                    </p>
                                    <p class="c5"><span class="c10">Prohibited Uses</span><a id="id.1ci93xb"></a></p>
                                    <p class="c5"><span class="c2">You may use the Site only for lawful purposes and in accordance with these Terms and
                                            Conditions. You agree not to use the Site:</span></p>
                                    <ul class="c11 lst-kix_list_31-0">
                                        <li class="c0"><span class="c2">In any way that violates any applicable federal, state, local, or international
                                                law or regulation (including, without limitation, any laws regarding the export of data or software to
                                                and from the US or other countries). </span></li>
                                        <li class="c0"><span class="c2">For the purpose of engaging, promoting, or encouraging illegal activity.</span>
                                        </li>
                                        <li class="c0"><span class="c2">For the purpose of exploiting, harming, or attempting to exploit or harm minors
                                                in any way by exposing them to inappropriate content, asking for personally identifiable information, or
                                                otherwise.</span></li>
                                        <li class="c0"><span class="c2">To send, knowingly receive, upload, download, use, or re-use any material that
                                                does not comply with the </span><span class="c27">Content Standards</span><span class="c2">&nbsp;set out
                                                in these Terms and Conditions.</span></li>
                                        <li class="c0"><span class="c2">To transmit, or procure the sending of, any advertising or promotional material
                                                without our prior written consent, including any &quot;junk mail,&quot; &quot;chain letter,&quot;
                                                &quot;spam,&quot; or any other similar solicitation.</span></li>
                                        <li class="c0"><span class="c2">To impersonate or attempt to impersonate the Company, a Company employee,
                                                another user, or any other person or entity (including, without limitation, by using email addresses
                                                associated with any of the foregoing).</span></li>
                                        <li class="c0"><span class="c2">To engage in any other conduct that restricts or inhibits anyone</span><a
                                                id="id.3whwml4"></a><span class="c2">&#39;s use or enjoyment of the Site, or which, as determined by us,
                                                may harm the Company or users of the Site, or expose them to liability.</span></li>
                                    </ul>
                                    <p class="c5"><span class="c2">Additionally, you agree not to:</span></p>
                                    <ul class="c11 lst-kix_list_31-0">
                                        <li class="c0"><span class="c2">Use the Site in any manner that could disable, overburden, damage, or impair the
                                                site or interfere with any other party&#39;s use of the Site, including their ability to engage in real
                                                time activities through the Site.</span></li>
                                        <li class="c0"><span class="c2">Use any robot, spider, or other automatic device, process, or means to access
                                                the Site for any purpose, including monitoring or copying any of the material on the Site.</span></li>
                                        <li class="c0"><span class="c2">Use any manual process to monitor or copy any of the material on the Site, or
                                                for any other purpose not expressly authorized in these Terms and Conditions, without our prior written
                                                consent.</span></li>
                                        <li class="c0"><span class="c2">Use any device, software, or routine that interferes with the proper working of
                                                the Site.</span></li>
                                        <li class="c0"><span class="c2">Introduce any viruses, Trojan horses, worms, logic bombs, or other material that
                                                is malicious or technologically harmful.</span></li>
                                        <li class="c0"><span class="c2">Attempt to gain unauthorized access to, interfere with, damage, or disrupt any
                                                parts of the Site, the server on which the Site is stored, or any server, computer, or database
                                                connected to the Site. </span></li>
                                        <li class="c0"><span class="c2">Attack the Site via a denial-of-service attack or a distributed
                                                denial-of-service attack.</span></li>
                                        <li class="c0"><span class="c2">Otherwise attempt to interfere with </span><a id="id.2bn6wsx"></a><span
                                                class="c2">the proper working of the Site.</span></li>
                                    </ul>
                                    <p class="c5"><span class="c10">User Contributions</span><a id="id.qsh70q"></a></p>
                                    <p class="c5"><span class="c2">The Site may contain message boards, personal web pages or profiles, forums, and
                                            other interactive features (collectively, &quot;</span><span class="c6">Interactive Services</span><span
                                            class="c2">&quot;) that allow users to post, submit, publish, display, or transmit to other users or other
                                            persons (hereinafter, &quot;</span><span class="c6">post</span><span class="c2">&quot;) content or materials
                                            (collectively, &quot;</span><span class="c6">User Contributions</span><a id="id.3as4poj"></a><span
                                            class="c2">&quot;) on or through the Site.</span></p>
                                    <p class="c5"><span class="c2">All User Contributions must comply with the Content Standards set out in these Terms
                                            and Conditions.</span></p><a id="id.1pxezwc"></a>
                                    <p class="c5"><span class="c2">Any User Contribution you post to the site will be considered non-confidential and
                                            non-proprietary. By providing any User Contribution on the Site, you grant us and our affiliates and service
                                            providers, and each of their and our respective licensees, successors, and as</span><a
                                            id="id.49x2ik5"></a><span class="c2">signs the right to use, reproduce, modify, perform, display,
                                            distribute, and otherwise disclose to third parties any such material for any purpose. </span></p>
                                    <p class="c5"><span class="c2">You represent and warrant that: </span></p>
                                    <ul class="c11 lst-kix_list_31-0">
                                        <li class="c0"><span class="c2">You own or control all rights in and to the User Contributions and have the
                                                right to grant the license granted above to us and our affiliates and service providers, and each of
                                                their and our respective licensees, successors, and assigns.</span></li>
                                    </ul><a id="id.2p2csry"></a>
                                    <ul class="c11 lst-kix_list_31-0">
                                        <li class="c0"><span class="c2">All of your User Contributions do and will comply with these Terms and
                                                Conditions. </span></li>
                                    </ul>
                                    <p class="c5"><span class="c2">You unde</span><a id="id.147n2zr"></a><span class="c2">rstand and acknowledge that
                                            you are responsible for any User Contributions you submit or contribute, and you, not the Company, have full
                                            responsibility for such content, including its legality, reliability, accuracy, and appropriateness.</span>
                                    </p>
                                    <p class="c5"><span class="c2">We are not respons</span><a id="id.3o7alnk"></a><span class="c2">ible or liable to
                                            any third party for the content or accuracy of any User Contributions posted by you or any other user of the
                                            Site. </span></p>
                                    <p class="c5"><span class="c10">Monitoring and Enforcement; Termination</span><a id="id.23ckvvd"></a></p>
                                    <p class="c5"><span class="c2">We have the right to:</span></p>
                                    <ul class="c11 lst-kix_list_31-0">
                                        <li class="c0"><span class="c2">Remove or refuse to post any User Contributions for any or no reason in our sole
                                                discretion.</span></li>
                                        <li class="c0"><span class="c2">Take any action with respect to any User Contribution that we deem necessary or
                                                appropriate in our sole discretion, including if we believe that such User Contribution violates the
                                                Terms and Conditions, including the Content Standards, infringes any intellectual property right or
                                                other right of any person or entity, threatens the personal safety of users of the Site or the public,
                                                or could create liability for the Company.</span></li>
                                        <li class="c0"><span class="c2">Disclose your identity or other information about you to any third party who
                                                claims that material posted by you violates their rights, including their intellectual property rights
                                                or their right to privacy.</span></li>
                                        <li class="c0"><span class="c2">Take appropriate legal action, including without limitation, referral to law
                                                enforcement, for any illegal or unauthorized use of the Site. </span></li>
                                    </ul><a id="id.ihv636"></a>
                                    <ul class="c11 lst-kix_list_31-0">
                                        <li class="c0"><span class="c2">Terminate or suspend your access to all or part of the Site for any or no
                                                reason, including without limitation, any violation of these Terms and Conditions.</span></li>
                                    </ul>
                                    <p class="c5"><span class="c2">Without limiting the foregoing, we have the right to cooperate fully with any law
                                            enforcement authorities or court order requesting or directing us to disclose the identity or other
                                            information of anyone posting any materials on or through the Site. YOU WAIVE AND HOLD HARMLESS THE COMPANY
                                            AND ITS AFFILIATES, LICENS</span><a id="id.32hioqz"></a><span class="c2">EES, AND SERVICE PROVIDERS FROM ANY
                                            CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES DURING, OR TAKEN AS A CONSEQUENCE OF,
                                            INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.</span></p>
                                    <p class="c5"><span class="c2">However, we cannot and do not undertake to review all material before it is posted on
                                            the Site, and cannot ensure prompt removal of objectionable material after it has been posted. Accordingly,
                                            we assume no liability for any action or inaction regarding transmissions, communications, or content
                                            p</span><a id="id.1hmsyys"></a><span class="c2">rovided by any user or third party. We have no liability or
                                            responsibility to anyone for performance or nonperformance of the activities described in this section.
                                        </span></p>
                                    <p class="c5"><span class="c10">Content Standards</span><a id="id.41mghml"></a></p>
                                    <p class="c5"><span class="c2">These content standards apply to any and all User Contributions and use of
                                            Interactive Services. User Contributions must in their entirety comply with all applicable federal, state,
                                            local, and international laws and regulations. Without limiting the foregoing, User Contributions must
                                            not:</span></p>
                                    <ul class="c11 lst-kix_list_31-0">
                                        <li class="c0"><span class="c2">Contain any material that is defamatory, obscene, indecent, abusive, offensive,
                                                harassing, violent, hateful, inflammatory, or otherwise objectionable.</span></li>
                                        <li class="c0"><span class="c2">Promote sexually explicit or pornographic material, violence, or discrimination
                                                based on race, sex, religion, nationality, disability, sexual orientation, or age.</span></li>
                                        <li class="c0"><span class="c2">Infringe any patent, trademark, trade secret, copyright, or other intellectual
                                                property or other rights of any other person.</span></li>
                                        <li class="c0"><span class="c2">Violate the legal rights (including the rights of publicity and privacy) of
                                                others or contain any material that could give rise to any civil or criminal liability under applicable
                                                laws or regulations or that otherwise may be in conflict with these Terms and Conditions and our Privacy
                                                Policy (linked above).</span></li>
                                        <li class="c0"><span class="c2">Be likely to deceive any person.</span></li>
                                        <li class="c0"><span class="c2">Promote any illegal activity, or advocate, promote, or assist any unlawful
                                                act.</span></li>
                                        <li class="c0"><span class="c2">Cause annoyance, inconvenience, or needless anxiety or be likely to upset,
                                                embarrass, alarm, or annoy any other person.</span></li>
                                        <li class="c0"><span class="c2">Impersonate any person, or misrepresent your identity or affiliation with any
                                                person or organization. </span></li>
                                        <li class="c0"><span class="c2">Involve commercial activities or sales, such as contests, sweepstakes, and other
                                                sales promotions, barter, or advertising.</span></li>
                                        <li class="c0"><span class="c2">Give the impression that they emanate from or are endorsed by us or any other
                                                person or entity, if this is not th</span><a id="id.2grqrue"></a><span class="c2">e case.</span></li>
                                    </ul>
                                    <p class="c5"><span class="c10">Reliance on Information Posted</span><a id="id.vx1227"></a></p>
                                    <p class="c5"><span class="c2">The information presented on or through the Site is made available solely for general
                                            information purposes. We do not warrant the accuracy, completeness, or usefulness of this information. Any
                                            reliance you place on su</span><a id="id.3fwokq0"></a><span class="c2">ch information is strictly at your
                                            own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by
                                            you or any other visitor to the Site, or by anyone who may be informed of any of its contents.</span></p>
                                    <p class="c5"><span class="c2">This Site may include content provided by third parties, including materials provided
                                            by Licensees, other users, third-party licensors, syndicators, aggregators, and/or reporting services. All
                                            statements and/or opinions expressed in these materials, and all articles and responses to questions and
                                            other content, other than the content provided by the Company, are solely the opinions and the
                                            responsibility of the person or entity providing those materials. These materials do not necessarily reflect
                                            the opinion of the Company.</span><a id="id.1v1yuxt"></a><span class="c2">&nbsp;We are not responsible, or
                                            liable to you or any third party, for the content or accuracy of any materials provided by any third
                                            parties.</span></p>
                                    <p class="c5"><span class="c10">Changes to the Site</span><a id="id.4f1mdlm"></a></p>
                                    <p class="c5"><span class="c2">We may update the content on this Site from time to time, but its content is not
                                            necessarily compl</span><a id="id.2u6wntf"></a><span class="c2">ete or up-to-date. Any of the material on
                                            the Site may be out of date at any given time, and we are under no obligation to update such material.
                                        </span></p>
                                    <p class="c5"><span class="c10">Information About You and Your Visits to the Site</span><a id="id.19c6y18"></a></p>
                                    <p class="c5"><span class="c2">All information we collect on this Site is subject to our Privacy Policy (linked
                                            above). By using the Site, you consent to all actions taken by us with respect to your information in
                                            compliance with the Privacy Policy. </span></p>
                                    <p class="c5"><span class="c10">Terms of Sale</span><a id="id.3tbugp1"></a></p>
                                    <p class="c5"><span class="c2">All purchases through the Site or other transactions for the sale of Services through
                                            the Site, or resulting from visits made by you to the Site, are governed by these Terms of Sale and any
                                            additional terms which are set forth in our invoices and proposals:</span></p>
                                    <ul class="c11 lst-kix_list_33-0 start">
                                        <li class="c4"><span class="c2">Scope of Services and Deliverables</span></li>
                                    </ul>
                                    <ul class="c11 lst-kix_list_33-1 start">
                                        <li class="c5 c15"><span class="c2">We will provide visualization services and / or the license subscription for
                                                the App upon Client&rsquo;s request (the &ldquo;</span><span class="c6">Services</span><span
                                                class="c2">&rdquo;). Such Services include but are not limited to 2D black &amp; white or color site
                                                plans, black &amp; white or color elevations, drone photography/drone mapping, photorealistic site plan
                                                options, 2D &amp; 3D floor plan options, 3D exterior and interior renderings, 3D photorealistic animated
                                                tours, CGI aerials with drone integration, 360&deg; panorama renderings and watercolor renderings, and
                                                any other rendering and visualization services that we offer. </span></li>
                                    </ul>
                                    <ul class="c11 lst-kix_list_33-0">
                                        <li class="c4"><span class="c2">Prices and Plans</span></li>
                                    </ul><a id="t.bce1f5ee65140e9ce5661ef5d59bedb80eae25ac"></a><a id="t.0"></a>
                                    <table class="c36">
                                        <tbody>
                                            <tr class="c9">
                                                <td class="c38" colspan="1" rowspan="1">
                                                    <p class="c20"><span class="c12"></span></p>
                                                </td>
                                                <td class="c24" colspan="1" rowspan="1">
                                                    <p class="c7"><span class="c12">BASIC</span></p>
                                                </td>
                                                <td class="c32" colspan="1" rowspan="1">
                                                    <p class="c7"><span class="c12">PREMIUM</span></p>
                                                </td>
                                                <td class="c28" colspan="1" rowspan="1">
                                                    <p class="c7"><span class="c12">CUSTOM</span></p>
                                                </td>
                                            </tr>
                                            <tr class="c9">
                                                <td class="c17" colspan="1" rowspan="1">
                                                    <p class="c5"><span class="c30">Price per month (Apply 10% off per semester or 20% off per year
                                                        </span><span class="c19 c30">if paid in advance)</span><br><br></p>
                                                </td>
                                                <td class="c18" colspan="1" rowspan="1">
                                                    <p class="c7"><span class="c12">150 USD</span></p>
                                                </td>
                                                <td class="c13" colspan="1" rowspan="1">
                                                    <p class="c7"><span class="c12">300 USD</span></p>
                                                </td>
                                                <td class="c21" colspan="1" rowspan="1">
                                                    <p class="c7"><span class="c12">Custom</span></p>
                                                </td>
                                            </tr>
                                            <tr class="c9">
                                                <td class="c17" colspan="1" rowspan="1">
                                                    <p class="c5"><span class="c12">Features available</span></p>
                                                </td>
                                                <td class="c18" colspan="1" rowspan="1">
                                                    <p class="c7"><span class="c12">3</span></p>
                                                </td>
                                                <td class="c13" colspan="1" rowspan="1">
                                                    <p class="c7"><span class="c12">5</span></p>
                                                </td>
                                                <td class="c21" colspan="1" rowspan="1">
                                                    <p class="c7"><span class="c12">Custom</span></p>
                                                </td>
                                            </tr>
                                            <tr class="c9">
                                                <td class="c17" colspan="1" rowspan="1">
                                                    <p class="c5"><span class="c12">Lead management system</span></p>
                                                </td>
                                                <td class="c18" colspan="1" rowspan="1">
                                                    <p class="c7"><span class="c12">Included</span></p>
                                                </td>
                                                <td class="c13" colspan="1" rowspan="1">
                                                    <p class="c7"><span class="c12">Included</span></p>
                                                </td>
                                                <td class="c21" colspan="1" rowspan="1">
                                                    <p class="c7"><span class="c12">Included</span></p>
                                                </td>
                                            </tr>
                                            <tr class="c9">
                                                <td class="c17" colspan="1" rowspan="1">
                                                    <p class="c5"><span class="c12">Support</span></p>
                                                </td>
                                                <td class="c18" colspan="1" rowspan="1">
                                                    <p class="c7"><span class="c12">Monday to Friday</span></p>
                                                    <p class="c7"><span class="c12">(9 to 5 pm)</span></p>
                                                </td>
                                                <td class="c13" colspan="1" rowspan="1">
                                                    <p class="c7"><span class="c12">Monday to Friday</span></p>
                                                    <p class="c7"><span class="c12">(9 to 5 pm)</span></p>
                                                </td>
                                                <td class="c21" colspan="1" rowspan="1">
                                                    <p class="c7"><span class="c12">Monday to Friday</span></p>
                                                    <p class="c7"><span class="c12">(9 to 5 pm)</span></p>
                                                </td>
                                            </tr>
                                            <tr class="c9">
                                                <td class="c17" colspan="1" rowspan="1">
                                                    <p class="c5"><span class="c12">Capacity</span></p>
                                                </td>
                                                <td class="c18" colspan="1" rowspan="1">
                                                    <p class="c7"><span class="c12">Up to 10 elevations</span></p>
                                                </td>
                                                <td class="c13" colspan="1" rowspan="1">
                                                    <p class="c7"><span class="c12">Up to 25 elevations</span></p>
                                                </td>
                                                <td class="c21" colspan="1" rowspan="1">
                                                    <p class="c7"><span class="c12">Custom</span></p>
                                                </td>
                                            </tr>
                                            <tr class="c9">
                                                        <td class="c17" colspan="1" rowspan="1">
                                                            <p class="c5"><span class="c12">Set up cost (*)</span></p>
                                                        </td>
                                                        <td class="c18" colspan="1" rowspan="1">
                                                            <p class="c7"><span class="c12">Not included</span></p>
                                                        </td>
                                                        <td class="c13" colspan="1" rowspan="1">
                                                            <p class="c7"><span class="c12">Not included</span></p>
                                                        </td>
                                                        <td class="c21" colspan="1" rowspan="1">
                                                            <p class="c7"><span class="c12">Not included</span></p>
                                                        </td>
                                                    </tr>                                    
                                            <tr class="c9">
                                                <td class="c17" colspan="1" rowspan="1">
                                                    <p class="c5"><span class="c12">Renderings/visuals (**)</span></p>
                                                </td>
                                                <td class="c18" colspan="1" rowspan="1">
                                                    <p class="c7"><span class="c12">Not included</span></p>
                                                </td>
                                                <td class="c13" colspan="1" rowspan="1">
                                                    <p class="c7"><span class="c12">Not included</span></p>
                                                </td>
                                                <td class="c21" colspan="1" rowspan="1">
                                                    <p class="c7"><span class="c12">Not included</span></p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p class="c5 c41"><span class="c2">One hour of client training included. Additional training time required
                                             by client will be charged at $85 USD per hour.</span></p> 
                                    <p class="c5 c41"><span class="c2">(*) Set up cost is a one-time payment to be quoted based on the chosen
                                            subscription (Starting at 250 USD for basic and premium subscriptions).</span></p>   
                                    <p class="c5 c41"><span class="c2">Set up cost includes uploading clients’ logo, base colors and app deploy. 
                                             </span></p>                                                                 
                                    <p class="c5 c41"><span class="c2">(**) Renderings/visuals are one-time payment according to pricing structure
                                            explained further per render, per interactive element and options.</span></p>
                                    <ol class="c11 lst-kix_list_34-0 start" start="1">
                                        <li class="c1"><span class="c2">Base Renderings. To be quoted based on the specific project requirement,
                                                information and existing drawings/floorplans.</span></li>
                                    </ol>
                                    <p class="c23 c22 c37"><span class="c2"></span></p>
                                    <ol class="c11 lst-kix_list_34-0" start="2">
                                        <li class="c1"><span class="c2">Interactive elements and options. Any surface or texture that needs to be
                                                rendered will be considered an option.</span></li>
                                    </ol>
                                    <ol class="c11 lst-kix_list_35-2 start" start="1">
                                        <li class="c23 c33"><span class="c2">$50 per interactive element for renderings (interior/exterior). Applicable
                                                to those elements that have any different feature from the original one.</span></li>
                                        <li class="c23 c33"><span class="c2">The above cost for rendering interactivity considers up to 5 options.
                                                Additional options charged at $10 per option .</span></li>
                                        <li class="c23 c33"><span class="c2">$50 per option for floorplans.</span></li>
                                    </ol>
                                    <ul class="c11 lst-kix_list_33-0">
                                        <li class="c4"><span class="c2">Payment Terms </span></li>
                                    </ul>
                                    <ul class="c11 lst-kix_list_33-1 start">
                                        <li class="c5 c15"><span class="c2 c19">The prices payable by you for the services supplied by the Company will be
                                                specified in the applicable proposal and according to the plans and options described above. Unless
                                                otherwise expressly stated in a proposal, payments are due the last day of each month. If Client does
                                                not pay an invoiced amount within terms, Company reserves the right to suspend the Services. In the
                                                event that Client&rsquo;s account is more than ninety (90) days in arrears, Client shall reimburse
                                                Company for the reasonable costs, including attorney&rsquo;s fees, of collecting such amounts from
                                                Client. </span></li>
                                        <li class="c5 c15"><span class="c2 c19">Client shall make all payments hereunder by wire, check, or credit card
                                                and in US dollars.</span><span class="c2">&nbsp;</span></li>
                                        <li class="c5 c15"><span class="c2 c19">Client </span><span class="c2">shall pay interest on all late payments
                                                at the lesser of the rate of 1.5% per month or the highest rate permissible under applicable law,
                                                calculated daily and compounded monthly. </span><span class="c2 c19">Client </span><span
                                                class="c2">shall reimburse the Company for all costs incurred in collecting any late payments,
                                                including, without limitation, attorneys&#39; fees. In addition to all other remedies available under
                                                these Terms or at law (which the Company does not waive by the exercise of any rights hereunder), the
                                                Company shall be entitled to suspend the Services if Client fails to pay any amounts when due
                                                hereunder.</span></li>
                                        <li class="c5 c15"><span class="c2">Client shall not withhold payment of any amounts due and payable by reason
                                                of any set-off of any claim or dispute with the Company, whether relating to the Company&rsquo;s breach,
                                                bankruptcy or otherwise.</span></li>
                                        <li class="c5 c15"><span class="c2">Changes in, or receipt of incomplete/uncoordinated drawing sets are
                                                initiation of services may require additional 3D modeling services which will be billed at a rate of
                                                $85/hr. If this is the case, we will send a separate proposal for the price and delivery schedule to be
                                                approved by Client before making any changes.</span></li>
                                    </ul>
                                    <ul class="c11 lst-kix_list_33-0">
                                        <li class="c4"><span class="c2">Additional Conditions</span></li>
                                    </ul>
                                    <ul class="c11 lst-kix_list_33-1 start">
                                        <li class="c5 c15"><span class="c2">Once a proposal is approved and the final project delivered, client agrees
                                                to pay according the agreed-upon payment terms and price, with such terms included in these Terms and
                                                Conditions and each individual proposal.</span></li>
                                        <li class="c5 c15"><span class="c2">Client understands that drawings and renderings are approximates that are
                                                for illustrative purposes only.</span></li>
                                        <li class="c5 c15"><span class="c2">Client understands and accepts that the services that are provided to Client
                                                may be used by us for promotional purposes (portfolio, website, social media, flyers, etc.). If such
                                                services are used by us for promotional purposes, proper credit will be attributed to Client.</span>
                                        </li>
                                    </ul>
                                    <ul class="c11 lst-kix_list_33-0">
                                        <li class="c4"><span class="c2">Termination</span></li>
                                    </ul>
                                    <ul class="c11 lst-kix_list_33-1 start">
                                        <li class="c3"><span class="c2">Any of the parties may terminate a project upon written notice at any time. Any
                                                termination will not affect Client&rsquo;s obligation to pay us for services previously rendered.</span>
                                        </li>
                                    </ul>
                                    <ul class="c11 lst-kix_list_33-0">
                                        <li class="c26"><span class="c2">THE COMPANY MAKES NO WARRANTY WHATSOEVER WITH RESPECT TO THE SERVICES,
                                                INCLUDING ANY (a) WARRANTY OF MERCHANTABILITY; (b) WARRANTY OF FITNESS FOR A PARTICULAR PURPOSE; OR (c)
                                                WARRANTY AGAINST INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS OF A THIRD PARTY, WHETHER EXPRESS OR
                                                IMPLIED BY LAW, COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OF TRADE, OR OTHERWISE.</span></li>
                                        <li class="c26"><span class="c2">Limitation of Liability.</span></li>
                                    </ul>
                                    <ul class="c11 lst-kix_list_33-1 start">
                                        <li class="c3"><span class="c2">IN NO EVENT SHALL THE COMPANY BE LIABLE TO CLIENT OR ANY THIRD PARTY FOR ANY
                                                LOSS OF USE, REVENUE OR PROFIT [OR LOSS OF DATA OR DIMINUTION IN VALUE], OR FOR ANY CONSEQUENTIAL,
                                                INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR PUNITIVE DAMAGES WHETHER ARISING OUT OF BREACH OF CONTRACT,
                                                TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE AND
                                                WHETHER OR NOT THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, AND NOTWITHSTANDING THE
                                                FAILURE OF ANY AGREED OR OTHER REMEDY OF ITS ESSENTIAL PURPOSE.</span></li>
                                        <li class="c3"><span class="c2">IN NO EVENT SHALL THE COMPANY&rsquo;S AGGREGATE LIABILITY ARISING OUT OF OR
                                                RELATED TO THIS AGREEMENT, WHETHER ARISING OUT OF OR RELATED TO BREACH OF CONTRACT, TORT (INCLUDING
                                                NEGLIGENCE), OR OTHERWISE, EXCEED THE TOTAL OF THE AMOUNTS PAID TO THE COMPANY FOR THE SERVICES SOLD
                                                HEREUNDER.</span></li>
                                    </ul>
                                    <ul class="c11 lst-kix_list_33-0">
                                        <li class="c26"><span class="c2">Force Majeure. The Company shall not be liable or responsible to Client, nor be
                                                deemed to have defaulted or breached these Terms and Conditions, for any failure or delay in fulfilling
                                                or performing any term of these Terms and Conditions or any invoice or proposal when and to the extent
                                                such failure or delay is caused by or results from acts or circumstances beyond the reasonable control
                                                of the Company including, without limitation, acts of God, flood, fire, earthquake, explosion,
                                                governmental actions, war, invasion, or hostilities (whether war is declared or not), terrorist threats
                                                or acts, riot, or other civil unrest, national emergency, revolution, insurrection, epidemic, lockouts,
                                                strikes or other labor disputes (whether or not relating to either party&#39;s workforce), or restraints
                                                or delays affecting carriers or inability or delay in obtaining supplies of adequate or suitable
                                                materials, materials or telecommunication breakdown or power outage, provided that, if the event in
                                                question continues for a continuous period in excess of ten (10) days, Client shall be entitled to give
                                                notice in writing to Company to terminate Services.</span><a id="id.28h4qwu"></a></li>
                                    </ul>
                                    <p class="c5"><span class="c2">Additional terms and conditions may also apply to specific portions, services, or
                                            features of </span><a id="id.nmf14n"></a><span class="c2">the Site. All such additional terms and conditions
                                            are hereby incorporated by this reference into these Terms and Conditions.</span></p>
                                    <p class="c5"><span class="c10">Linking to the Site and Social Media Features</span><a id="id.37m2jsg"></a></p>
                                    <p class="c5"><span class="c2">You may link to our homepage, provided you do so in a way that is fair and legal
                                            and</span><a id="id.1mrcu09"></a><span class="c2">&nbsp;does not damage our reputation or take advantage of
                                            it, but you must not establish a link in such a way as to suggest any form of association, approval, or
                                            endorsement on our part without our express written consent. </span></p>
                                    <p class="c5"><span class="c2">This Site may provide certain social media features that enable you to:</span></p>
                                    <ul class="c11 lst-kix_list_31-0">
                                        <li class="c0"><span class="c2">Link from your own or certain third-party websites to certain content on this
                                                Site.</span></li>
                                        <li class="c0"><span class="c2">Send emails or other communications with certain content, or links to certain
                                                content, on this Site.</span></li>
                                        <li class="c0"><span class="c2">Cause limited portions of content on</span><a id="id.46r0co2"></a><span
                                                class="c2">&nbsp;this Site to be displayed or appear to be displayed on your own or certain third-party
                                                websites.</span></li>
                                    </ul>
                                    <p class="c5"><span class="c2">You may use these features solely as they are provided by us, and solely with respect
                                            to the content they are displayed with, and otherwise in accordance with any additional terms and conditions
                                            we provide with respect to such features. Subject to the foregoing, you must not:</span></p>
                                    <ul class="c11 lst-kix_list_31-0">
                                        <li class="c0"><span class="c2">Establish a link from any website that is not owned by you.</span></li>
                                        <li class="c0"><span class="c2">Cause the Site or portions of it to be displayed on, or appear to be displayed
                                                by, any other site, for example, framing, deep linking, or in-line linking.</span></li>
                                        <li class="c0"><span class="c2">Link to any part of the Site other than the homepage.</span></li>
                                        <li class="c0"><span class="c2">Otherwise take any action with respect to the materials on this Site that is
                                                inconsistent with any other provision of these </span><a id="id.2lwamvv"></a><span class="c2">Terms and
                                                Conditions.</span></li>
                                    </ul>
                                    <p class="c5"><span class="c2">The website from which you are linking, or on which you make certain content
                                            accessible, must comply in all respects with the Content Standards set out in these Terms and
                                            Conditions.</span></p><a id="id.111kx3o"></a>
                                    <p class="c5"><span class="c2">You agree to cooperate with us in causing any unaut</span><a
                                            id="id.3l18frh"></a><span class="c2">horized framing or linking immediately to stop. We reserve the right to
                                            withdraw linking permission without notice.</span></p>
                                    <p class="c5"><span class="c2">We may disable all or any social media features and any links at any time without
                                            notice in our discretion. </span></p><a id="id.206ipza"></a>
                                    <p class="c5"><span class="c10">Links from the Site</span><a id="id.4k668n3"></a></p>
                                    <p class="c5"><span class="c2">If the Site contains links to other sites and resources provided by third parties,
                                            these links are provided for your convenience only. This includes links contained in advertisements,
                                            including banner advertisements and sponsored links. We have no control over the contents of those sites or
                                            resources, and accept no responsibility for them or for any loss or damage that may arise from your use of
                                            them. If you decide to access any of the third-party websites linked to this Site, you do so entirely at
                                            your own risk and s</span><a id="id.2zbgiuw"></a><span class="c2">ubject to the terms and conditions of use
                                            for such websites.</span></p>
                                    <p class="c5"><span class="c10">Geographic Restrictions</span><a id="id.1egqt2p"></a></p>
                                    <p class="c5"><span class="c2">The owner of the Site is based in the State of Florida in the United States. We
                                            provide this Site for use only by persons located in the United States. We make no claims that the Site or
                                            any of its content is accessible or appropriate outside of the United States. Access to the Site may not be
                                            legal by certain persons or in certain countries. If you access the Site from outside the United States, you
                                            do so on your own initi</span><a id="id.3ygebqi"></a><span class="c2">ative and are responsible for
                                            compliance with local laws.</span></p>
                                    <p class="c5"><span class="c10">Disclaimer of Warranties</span><a id="id.2dlolyb"></a></p>
                                    <p class="c5"><span class="c2">You understand that we cannot and do not guarantee or warrant that files available
                                            for downloading from the internet or the Site will be free of viruses or other destructive code. You are
                                            responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements
                                            for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our
                                            site for any reconstruction of any lost data. TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE
                                            FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY
                                            HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER </span><a id="id.sqyw64"></a><span
                                            class="c2">PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR
                                            ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE
                                            LINKED TO IT.</span></p>
                                    <p class="c5"><span class="c2">YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE
                                            WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE
                                            WEBSITE ARE PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS, WITHOUT ANY WARRANTIES OF
                                            ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES
                                            ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY,
                                            OR AVAILABILITY OF THE WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED
                                            WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED
                                            THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE
                                            CORRECTED, </span><a id="id.3cqmetx"></a><span class="c2">THAT OUR SITE OR THE SERVER THAT MAKES IT
                                            AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE WEBSITE OR ANY SERVICES OR ITEMS
                                            OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS. </span></p>
                                    <p class="c5"><span class="c2">TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF
                                            ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES
                                            OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURP</span><a id="id.1rvwp1q"></a><span
                                            class="c2">OSE.</span></p>
                                    <p class="c5"><span class="c2">THE FOREGOING DOES NO</span><a id="id.4bvk7pj"></a><span class="c2">T AFFECT ANY
                                            WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</span></p>
                                    <p class="c5"><span class="c10">Limitation on Liability</span><a id="id.2r0uhxc"></a></p>
                                    <p class="c5"><span class="c2">TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY, ITS AFFILIATES,
                                            OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF
                                            ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE
                                            SITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT,
                                            INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL
                                            INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR
                                            ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING
                                            NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE. </span></p>
                                    <p class="c5"><span class="c2">The limitation of liability set out above does not apply to liability resulting from
                                            our gross negligence or willful miscondu</span><a id="id.1664s55"></a><span class="c2">ct.</span></p>
                                    <p class="c5"><span class="c2">THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER
                                            APPLICABLE LAW.</span><a id="id.3q5sasy"></a></p>
                                    <p class="c5"><span class="c10">Indemnification</span><a id="id.25b2l0r"></a></p>
                                    <p class="c5"><span class="c2">You agree to defend, indemnify, and hold harmless the Company, its affiliates,
                                            licensors, and service providers, and its and their respective officers, directors, employees, contractors,
                                            agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages,
                                            judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys&#39; fees) arising out
                                            of or relating to your violation of these Terms and Conditions or your use of the Site, including, but not
                                            limited to, your User Contributions, any use of the Site&#39;s content, services, and products other than as
                                            expressly authorized in these Terms and Conditions</span><a id="id.kgcv8k"></a><span class="c2">, or your
                                            use of any information obtained from the Site.</span></p>
                                    <p class="c5"><span class="c10">Governing Law and Jurisdiction</span><a id="id.34g0dwd"></a></p>
                                    <p class="c5"><span class="c2">All matters relating to the Site and these Terms and Conditions, and any dispute or
                                            claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims),
                                            shall be governed by and construed in accordance with the internal laws of the State of Florida without
                                            giving effect to any choice or conflict of law provision or rule (whether of the State of Florida or any
                                            other jurisdicti</span><a id="id.1jlao46"></a><span class="c2">on).</span></p>
                                    <p class="c5"><span class="c2">Any legal suit, action, or proceeding arising out of, or related to, these Terms and
                                            Conditions or the Site shall be instituted exclusively in the federal courts of the United States or the
                                            courts of the State of Florida, in each case located in the County of Broward, although we retain the right
                                            to bring any suit, action, or proceeding against you for breach of these Terms and Conditions in your
                                            county, state, or country of residence or any other relevant county, state, or country. You waive any and
                                            all objecti</span><a id="id.43ky6rz"></a><span class="c2">ons to the exercise of jurisdiction over you by
                                            such courts and to venue in such courts.</span></p>
                                    <p class="c5"><span class="c10">Arbitration</span><a id="id.2iq8gzs"></a></p>
                                    <p class="c5"><span class="c2">At Company&#39;s sole discretion, it may require You to submit any disputes arising
                                            from these Terms and Conditions or use of the Site, including disputes aris</span><a
                                            id="id.xvir7l"></a><span class="c2">ing from or concerning their interpretation, violation, invalidity,
                                            non-performance, or termination, to final and binding arbitration under the Rules of Arbitration of the
                                            American Arbitration Association applying Florida law.</span></p>
                                    <p class="c5"><span class="c10">Limitation on Time to File Claims</span><a id="id.3hv69ve"></a></p>
                                    <p class="c5"><span class="c2">ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS
                                            AND CONDITIONS OR THE WEBSITE MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES;
                                            OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTL</span><a id="id.1x0gk37"></a><span class="c2">Y
                                            BARRED.</span></p>
                                    <p class="c5"><span class="c10">Waiver and Severability</span><a id="id.4h042r0"></a></p>
                                    <p class="c5"><span class="c2">No waiver by the Company of any term or condition set out in these Terms and
                                            Conditions shall be deemed a further or continuing waiver of such term or condition or a waiver of any other
                                            term or condition, and any failure of the Comp</span><a id="id.2w5ecyt"></a><span class="c2">any to assert a
                                            right or provision under these Terms and Conditions shall not constitute a waiver of such right or
                                            provision.</span></p>
                                    <p class="c5"><span class="c2">If any provision of these Terms and Conditions is held by a court or other tribunal
                                            of competent jurisdiction to be invalid, illeg</span><a id="id.1baon6m"></a><span class="c2">al, or
                                            unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that
                                            the remaining provisions of the Terms and Conditions will continue in full force and effect. </span></p>
                                    <p class="c5"><span class="c10">Entire Agreement</span><a id="id.3vac5uf"></a></p>
                                    <p class="c5"><span class="c2">The Terms and Conditions, our Privacy Policy, estimate, and invoice, or order
                                            confirmation, &nbsp;constitute the sole and entire agreement between you and the Company regarding the Site
                                            and supersede all prior and contemporaneous understandings, agreements, representations, and warranties,
                                        </span><a id="id.2afmg28"></a><span class="c2">both written and oral, regarding the Site. </span></p>
                                    <p class="c5"><span class="c10">Your Comments and Concerns</span><a id="id.pkwqa1"></a></p>
                                    <p class="c5"><span class="c2">This website is operated by </span><span class="c6">Artistic Visions LLC</span><span
                                            class="c2">&nbsp;located at 900 SE Ocean Boulevard, Building E Suite 244, Stuart, Florida 34994.</span></p>
                                    <a id="id.39kk8xu"></a>
                                    <p class="c5 c22"><span class="c2"></span></p>
                                    <p class="c23 c19"><span class="c2">If you believe someone is infringing your copyright, you can report it to us by
                                            contact our designated agent identified below. &nbsp;</span></p>
                                    <p class="c23 c22 c19"><span class="c2"></span></p>
                                    <p class="c23 c19"><span class="c2">Aquileo Abello</span></p>
                                    <p class="c23 c19"><span class="c2">Business Development Director | ARTISTIC VISIONS, LLC</span></p>
                                    <p class="c23 c19"><span class="c2">900 SE Ocean Blvd | Bldg. E &nbsp;Suite 244 | Stuart, Fl 34994</span></p>
                                    <p class="c23 c19"><span class="c2">(t) 772.419.7107</span></p>
                                    <p class="c23 c22 c19"><span class="c2"></span></p>
                                    <p class="c19 c23"><span class="c2">If you contact our designated agent, please be sure to include a
                                            complete&nbsp;copyright claim&nbsp;in your report with the following information:</span></p>
                                    <p class="c23 c19"><span class="c2">&nbsp;</span></p>
                                    <ul class="c11 lst-kix_list_36-0 start">
                                        <li class="c14"><span class="c2">Your complete contact information (full name, mailing address and phone
                                                number).*</span></li>
                                        <li class="c14"><span class="c2">A description of the copyrighted work that you claim has been infringed.</span>
                                        </li>
                                        <li class="c14"><span class="c2">A description of the content on our site that you claim infringes your
                                                copyright.</span></li>
                                        <li class="c14"><span class="c2">Information reasonably sufficient to permit us to locate the material alleged
                                                to be infringing.</span></li>
                                        <li class="c14"><span class="c2">A declaration that:</span></li>
                                    </ul>
                                    <ol class="c11 lst-kix_list_36-1 start" start="1">
                                        <li class="c23 c19 c35"><span class="c2">You have a good faith belief that use of the copyrighted content
                                                described above, in the manner you have complained of, is not authorized by the copyright owner, its
                                                agent, or the law.</span></li>
                                        <li class="c23 c35 c19"><span class="c2">The information in your notice is accurate.</span></li>
                                        <li class="c23 c35 c19"><span class="c2">Under penalty of perjury, you are the owner or authorized to act on
                                                behalf of the owner of an exclusive copyright that is allegedly infringed.</span></li>
                                    </ol>
                                    <ul class="c11 lst-kix_list_36-0">
                                        <li class="c14"><span class="c2">Your electronic signature or physical signature.</span><a id="id.1opuj5n"></a>
                                        </li>
                                    </ul>
                                    <p class="c5 c22"><span class="c2"></span></p>
                                    <p class="c5"><span class="c2">All other feedback, comments, requests for technical support, and other
                                            communications relating to the Site should be directed to: <a href="mailto:support@avwaveinteractive.com">support@avwaveinteractive.com</a>.</span></p>
                                    <p class="c5 c22"><span class="c40"></span></p>
                </nb-card-body>
                <nb-card-footer><span>Last Modified: October 23, 2019</span></nb-card-footer>
        </nb-card>